
import React, { Component } from 'react';
import clickSound from "./assets/sound/hover.mp3";
import {Howl} from "howler";
import HoverSound from "./assets/sound/click-21156.mp3";
import MethStill from './assets/Items/Potion 1 HD-0 STILL.png';
import MethButton from './assets/Items/Potion 1 HD.gif';
import CrackStill from './assets/Items/Potion 2 HD-0 STILL.png';
import CrackButton from './assets/Items/Potion 2 HD.gif';
import HeroinStill from './assets/Items/Potion 3 HD-0 STILL.png';
import HeroinButton from './assets/Items/Potion 3 HD.gif';
import FentanylStill from './assets/Items/Potion 4 HD-0 STILL.png';
import FentanylButton from './assets/Items/Potion 4 HD.gif';
import styled from 'styled-components';

//LANDING
const size = {
  mobile: '480px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1200px'
};
const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(max-width: ${size.desktop})`,
  tv: `(min-width: ${parseInt(size.desktop) + 1}px)`
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media ${device.mobile} {
    align-items: center;
  }
`;
const Title = styled.h1`
  position: absolute;
  top: 25vh;
  left: 50%; /* Center the title horizontally */
  transform: translateX(-50%); /* Center the title horizontally */
  font-size: calc(1.8vh + 1.8vw);
  font-weight: bold;
  color: #000; /* Initial color set to black */
  transition: color 0.5s ease, text-shadow 0.5s ease; /* Smooth transition for color and text-shadow */
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 20px; /* Add padding to the title */
  z-index: 1; /* Ensure the title is above the background */

  /* Pseudo-element for background */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
    transition: background-color 0.5s ease; /* Smooth transition for background color */
    z-index: -1; /* Place the background behind the title */
    border-radius: 10px; /* Rounded corners */
  }

  /* Initial text-shadow for a soft shine effect */
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.4);

  &:hover {
    color: #fff; /* Brighter text on hover (white) */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.5); /* Reduced shine effect on hover */
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.6); /* Darker background on hover */
  }

  @media (max-width: 320px) {
    display: none; /* Hide title on very small screens */
  }

  @media (min-width: 321px) and (max-width: 768px) {
    top: 25vh;
    left: 50%; /* Center the title horizontally on tablets */
    transform: translateX(-50%); /* Center the title horizontally */
    font-size: calc(1.1vh + 1.1vw);
  }
`;
const Text = styled.p`
  position: absolute;
  top: 35vh;
  left: 32vw;
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;

  &:hover {
    color: red;
  }

  @media ${device.tablet} {
    top: 70vh;
    left: 37vw;
  }


`;
const BATTLEText = styled.p`
  position: absolute;
  top: 35vh;
  left: 60vw;
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;

  &:hover {
    color: red;
  }
  @media ${device.tablet} {
    top: 80vh;
    left: 37vw;
  }
`;
const MethElementContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Black shadow */
  width: auto;
  height: auto;
  text-align: center;
  display: inline-block;
  z-index: 1;

  /* Hover effect to grow the shadow and slightly enlarge the element */
  &:hover {
    box-shadow: 0 8px 20px rgba(100, 100, 1000, 60); /* Larger shadow on hover */
    transform: translate(-50%, -50%) scale(1.1); /* Slightly enlarge the element */
  }

  /* For viewport widths between 0px and 320px */
  @media (min-width: 0px) and (max-width: 320px) {
    margin: 10px;
    top: 50vh;
    left: 25vw;
    width: 60px;
    height: auto;
  }

  /* For viewport widths between 321px and 1200px */
  @media (min-width: 321px) and (max-width: 1200px) {
    margin: 10px;
    top: 50vh;
    left: 25vw;
    width: 90px;
    height: auto;
  }

  @media (min-width: 1201px) {
    margin: 10px;
    top: 50vh;
    left: 25vw;
    width: 120px;
    height: auto;
  }
`;
const MethElement = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;
const CrackElementContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Black shadow */
  width: auto;
  height: auto;
  text-align: center;
  display: inline-block;
  z-index: 1;

  /* Hover effect to grow the shadow and slightly enlarge the element */
  &:hover {
    box-shadow: 0 8px 20px rgba(255, 255, 0, 0.6); /* Yellow shadow on hover */
    transform: translate(-50%, -50%) scale(1.1); /* Slightly enlarge the element */
  }

  /* For viewport widths between 0px and 320px */
  @media (min-width: 0px) and (max-width: 320px) {
    margin: 10px;
    top: 50vh;
    left: 40vw;
    width: 60px;
    height: auto;
  }

  /* For viewport widths between 321px and 1200px */
  @media (min-width: 321px) and (max-width: 1200px) {
    margin: 10px;
    top: 50vh;
    left: 40vw;
    width: 90px;
    height: auto;
  }

  @media (min-width: 1201px) {
    margin: 10px;
    top: 50vh;
    left: 40vw;
    width: 120px;
    height: auto;
  }
`;
const CrackElement = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;
const HeroinElementContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Black shadow */

  width: auto;
  height: auto;
  text-align: center;
  display: inline-block;
  z-index: 1;

  /* Hover effect to grow the shadow and slightly enlarge the element */
  &:hover {
    box-shadow: 0 8px 20px rgba(255, 0, 0, 0.6); /* Red shadow on hover */
    transform: translate(-50%, -50%) scale(1.1); /* Slightly enlarge the element */
  }

  /* For viewport widths between 0px and 320px */
  @media (min-width: 0px) and (max-width: 320px) {
    margin: 10px;
    top: 50vh;
    left: 59vw;
    width: 160px;
    height: auto;
  }

  /* For viewport widths between 321px and 1200px */
  @media (min-width: 321px) and (max-width: 1200px) {
    margin: 10px;
    top: 50vh;
    left: 59vw;
    width: 90px;
    height: auto;
  }

  @media (min-width: 1201px) {
    margin: 10px;
    top: 50vh;
    left: 59vw;
    width: 120px;
    height: auto;
  }
`;
const HeroinElement = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;
const FentanylElementContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.3s ease-in-out; /* Added box-shadow transition */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
 box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); /* Black shadow */

  width: auto;
  height: auto;
  text-align: center;
  display: inline-block;
  z-index: 1;

  /* Hover effect to grow the shadow and slightly enlarge the element */
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 255, 0, 0.6); /* Green shadow on hover */
    transform: translate(-50%, -50%) scale(1.1); /* Slightly enlarge the element */
  }

  /* For viewport widths between 0px and 320px */
  @media (min-width: 0px) and (max-width: 320px) {
    margin: 10px;
    top: 50vh;
    left: 75vw;
    width: 60px;
    height: auto;
  }

  /* For viewport widths between 321px and 1200px */
  @media (min-width: 321px) and (max-width: 1200px) {
    margin: 10px;
    top: 50vh;
    left: 75vw;
    width: 90px;
    height: auto;
  }

  @media (min-width: 1201px) {
    margin: 10px;
    top: 50vh;
    left: 75vw;
    width: 120px;
    height: auto;
  }
`;
const FentanylElement = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`;
const HGMSCopyIcon = styled.span`
  cursor: pointer;
  font-size: 24px; 
  position: absolute;
  top: 35vh;
  left: 32vw;

  &:hover {
    opacity: 0.8;
    color: Blue;
  }
  @media (min-width: 0px) and (max-width: 320px) {
    display: none;
  }
  @media (min-width: 321px) and (max-width: 10000px) {
    display: none; /* Hide the title on mobile screens */
  }

`;
const BATTLECopyIcon = styled.span`
  cursor: pointer;
  font-size: 24px; 
  position: absolute;
  top: 35vh;
  left: 60vw;

  &:hover {
    opacity: 0.8;
    color: Blue;
  }
  @media (min-width: 0px) and (max-width: 320px) {
    display: none;
  }
  @media (min-width: 321px) and (max-width: 10000px) {
    display: none; /* Hide the title on mobile screens */
  }
`;
const handleTitleClick = () => {
  window.location.reload(); // Reload the page when the title is clicked
};

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        MethImage: MethStill,
        CrackImage: CrackStill,
        HeroinImage: HeroinStill,
        FentanylImage: FentanylStill,
        isMouseDown: false,
        mousedownTime: 0,
    };
    this.hoverover = new Howl({ 
      src: HoverSound,
      volume: 0.5 
    });
    this.click = new Howl({
      src: clickSound,
      volume: 0.3,
    });

}

  HoverOverPlay = () => {
    this.hoverover.play();
  }
  clickPlay = () => {
      this.click.play();
  }


handleMethMouseDown = () => {
  this.setState({ 
      MethImage: MethButton,
      mousedownTime: Date.now()
  });

  if (this.MethTimeout) {
      clearTimeout(this.MethTimeout);
  }

};
handleMethMouseUp = () => {
  if (this.MethTimeout) {
      clearTimeout(this.MethTimeout);
  }

  const elapsedTime = Date.now() - this.state.mousedownTime;

  if (elapsedTime < 900) {
      this.setState({ MethImage: MethButton });
      setTimeout(() => {
          this.setState({ MethImage: MethStill });
          this.navigateMeth();
      }, 900 - elapsedTime);
  } else {
      this.setState({ MethImage: MethStill });
      this.navigateMeth();
  }
};
handleCrackMouseDown = () => {
  this.setState({ 
      CrackImage: CrackButton,
      mousedownTime: Date.now()
  });

  if (this.CrackTimeout) {
      clearTimeout(this.MethTimeout);
  }

};
handleCrackMouseUp = () => {
  if (this.CrackTimeout) {
      clearTimeout(this.CrackTimeout);
  }

  const elapsedTime = Date.now() - this.state.mousedownTime;

  if (elapsedTime < 900) {
      this.setState({ CrackImage: CrackButton });
      setTimeout(() => {
          this.setState({ CrackImage: CrackStill });
          this.navigateCrack();
      }, 900 - elapsedTime);
  } else {
      this.setState({ CrackImage: CrackStill });
      this.navigateCrack();
  }
};
handleHeroinMouseDown = () => {
  this.setState({ 
    HeroinImage: HeroinButton,
      mousedownTime: Date.now()
  });

  if (this.HeroinTimeout) {
      clearTimeout(this.HeroinTimeout);
  }

};
handleHeroinMouseUp = () => {
  if (this.HeroinTimeout) {
      clearTimeout(this.HeroinTimeout);
  }

  const elapsedTime = Date.now() - this.state.mousedownTime;

  if (elapsedTime < 900) {
      this.setState({ HeroinImage: HeroinButton });
      setTimeout(() => {
          this.setState({ HeroinImage: HeroinStill });
          this.navigateHeroin();
      }, 900 - elapsedTime);
  } else {
      this.setState({ HeroinImage: HeroinStill });
      this.navigateHeroin();
  }
};
handleFentanylMouseDown = () => {
  this.setState({ 
    FentanylImage: FentanylButton,
      mousedownTime: Date.now()
  });

  if (this.FentanylTimeout) {
      clearTimeout(this.FentanylTimeout);
  }

};
handleFentanylMouseUp = () => {
  if (this.FentanylTimeout) {
      clearTimeout(this.FentanylTimeout);
  }

  const elapsedTime = Date.now() - this.state.mousedownTime;

  if (elapsedTime < 900) {
      this.setState({ FentanylImage: FentanylButton });
      setTimeout(() => {
          this.setState({ FentanylImage: FentanylStill });
          this.navigateFentanyl();
      }, 900 - elapsedTime);
  } else {
      this.setState({ FentanylImage: FentanylStill });
      this.navigateFentanyl();
  }
};
// navigateMeth = () => {
//   const { onButtonClick } = this.props;
//   if (typeof onButtonClick === 'function') {
//     onButtonClick('LeaderBoard'); 
//   }
// };

// handleMintMouseDown = () => {
//   this.setState({ 
//       MethImage: MintButton,
//       mousedownTime: Date.now()
//   });

//   if (this.MethTimeout) {
//       clearTimeout(this.MethTimeout);
//   }

//   this.MethTimeout = setTimeout(() => {
//       if (this.state.mintImage === MintButton) {
//           this.setState({ mintImage: MintDown });
//       }
//   }, 700);
// };

// handleMintMouseUp = () => {
//   if (this.MethTimeout) {
//       clearTimeout(this.MethTimeout);
//   }

//   const elapsedTime = Date.now() - this.state.mousedownTime;

//   if (elapsedTime < 900) {
//       this.setState({ mintImage: MintButton });
//       setTimeout(() => {
//           this.setState({ mintImage: MintStill });
//           this.navigateMint();
//       }, 900 - elapsedTime);
//   } else {
//       this.setState({ mintImage: MintStill });
//       this.navigateMint();
//   }
// };

// navigateMint = () => {
//   const { onButtonClick } = this.props;
//   if (typeof onButtonClick === 'function') {
//     onButtonClick('Mint');
//   }
// };

  render() {
    return (
      <div>
        <Title onClick={handleTitleClick}>"MR.COOK"</Title>;
        <Container>
        {this.state.copied ? (
            <Text>Copied!</Text>
          ) : (
            <HGMSCopyIcon onClick={this.copyHGMSAddress}>$HGMS CONTRACT</HGMSCopyIcon>
          )}
           {this.state.copiedBATTLE ? (
            <BATTLEText>Copied!</BATTLEText>
          ) : (
            <BATTLECopyIcon onClick={this.copyBATTLEAddress}>BATTLECONTRACT</BATTLECopyIcon>
          )}


          { <MethElementContainer> <MethElement 
              onMouseDown={this.handleMethMouseDown}
              onMouseUp={this.handleMethMouseUp}
              onMouseEnter={this.HoverOverPlay}>
              <img src={this.state.MethImage} alt="Meth" />
          </MethElement></MethElementContainer> }
          { <CrackElementContainer> <CrackElement 
              onMouseDown={this.handleCrackMouseDown}
              onMouseUp={this.handleCrackMouseUp}
              onMouseEnter={this.HoverOverPlay}>
              <img src={this.state.CrackImage} alt="Crack" />
          </CrackElement></CrackElementContainer> }
          { <HeroinElementContainer> <HeroinElement 
              onMouseDown={this.handleHeroinMouseDown}
              onMouseUp={this.handleHeroinMouseUp}
              onMouseEnter={this.HoverOverPlay}>
              <img src={this.state.HeroinImage} alt="Heroin" />
          </HeroinElement></HeroinElementContainer> }
          { <FentanylElementContainer> <FentanylElement 
              onMouseDown={this.handleFentanylMouseDown}
              onMouseUp={this.handleFentanylMouseUp}
              onMouseEnter={this.HoverOverPlay}>
              <img src={this.state.FentanylImage} alt="Fentanyl" />
          </FentanylElement></FentanylElementContainer> }
        </Container>
      </div>
    );
}

}

export default LandingPage;

